import { datadogRum } from '@datadog/browser-rum';

export default function initDatadog() {
    const env = document.head.querySelector("meta[name=datadog_env]")
    if (!env) return;
    datadogRum.init({
        applicationId: '8d0573c0-bd1e-4e85-8615-4aa8866c6da1',
        clientToken: 'pube58af6c0c13a54fc2cf8820e04dbe22c',
        site: 'us5.datadoghq.com',
        service:'landing-pages',
        sampleRate: 100,
        premiumSampleRate: 0,
        trackInteractions: true,
        trackResources: true,
        allowedTracingUrls: [/^https:\/\/([a-zA-Z0-9-]+\.)*sittercity\.com(\/|$)/],
        env: env.content,
    });
};