function trackClick(event) {
    const { cta } = event.target.dataset;
    if (window.analytics) {
        window.analytics.track("Featured Content Clicked", { source: cta });
    } else {
        console.log("Would have tracked segment event with source", cta);
    }
}

export default function () {
    const links = document.querySelectorAll('[data-cta]');
    links.forEach((link) => {
        link.addEventListener("click", trackClick);
    })
}